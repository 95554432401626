@use '../../../styles/utils/mixins.scss' as *;

.vehicleCard {
  .carLink {
    display: block;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    .carImage {
      margin: 0 auto;
      text-align: center;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }

  .cardHeader {
    .carInfo {
      .modelName {
        font-size: 36px;
        line-height: 43.2px;
        font-weight: 600;
        color: $kiaMidnightBlack;
        display: inline-block;
        margin-bottom: 5px;
        @media screen and (min-width: 992px) and (max-width: 1170px) {
          font-size: 30px;
          line-height: 38.2px;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      .description {
        color: $kiaBattleshipGrey;
        margin-bottom: 5px;
        padding-right: 5px;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        display: inline-block;
      }
    }
    .carPriceInfo {
      span {
        color: $kiaMidnightBlack;
        font-size: 12px;
        line-height: 14.4px;
        display: inline-block;
        vertical-align: bottom;
        text-decoration: none;
      }
      .carPrice {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $kiaMidnightBlack;
        @media screen and (min-width: 992px) and (max-width: 1180px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .promotionTag {
        background-color: $kiaAfternoonYellow;
        color: $kiaMidnightBlack;
        font-weight: 600;
        font-size: 12px;
        line-height: 14.4px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 3px;
        }
      }
      .newTag {
        background-color: $kiaMidnightBlack;
        color: $kiaPolarWhite;
        font-weight: 600;
        font-size: 12px;
        line-height: 14.4px;
        height: 24px;
        width: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: end;
        span {
          color: $kiaPolarWhite;
          margin-top: 3px;
          margin-left: 2px;
        }
      }
    }
  }
  .carDetailsWrapper {
    .borderLeft {
      border-color: $keyline;
    }
    .carDetails {
      .carLabel {
        font-weight: 300;
        font-size: 12px;
        color: $description;
        line-height: 16px;
      }
      .carUnit {
        strong {
          font-weight: 600;
          font-size: 14px;
          color: $kiaMidnightBlack;
          line-height: 16px;
        }
        span {
          font-weight: 300;
          font-size: 12px;
          color: $description;
          line-height: 16px;
        }
      }
    }
  }
  .features {
    .feature {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      color: $description;
    }
  }
  .cardFooter {
    .ctaBtn {
      padding: 13px 18px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
