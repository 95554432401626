@use '@/styles/utils/mixins.scss' as *;
.headingElement {
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    &.titleXl {
      font-size: 60px;
      line-height: 60px;
      @include tab() {
        font-size: 48px;
        line-height: 60px;
      }
      @include mob() {
        font-size: 36px;
        line-height: 43.2px;
      }
    }
    &.titleLg {
      font-size: 48px;
      line-height: 60px;
      @include tab() {
        font-size: 36px;
        line-height: 43.2px;
      }
      @include mob() {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    &.titleMd {
      font-size: 24px;
      line-height: 36px;
    }
    &.titleXs {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
  .titleText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    margin-bottom: 28px;
  }
}
.fleetTabsWrap {
  background-color: $kiaMidnightBlack;

  .KiaOneNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      overflow-x: auto;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      li.menuItem {
        margin: 24px;
        display: block;
        text-wrap: nowrap;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
        a {
          &.menuLink {
            padding: 13px 0;
            font-size: 14px;
            line-height: 16.8px;
            font-weight: 600;
            color: $kiaCoolGrey;
            position: relative;
            &::before {
              content: '';
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 11px;
              height: 1px;
              width: 0;
              background-color: $kiaPolarWhite;
              transition: width 0.3s;
            }
            &:hover::before,
            &:focus::before,
            &:active::before,
            &.menuLinkActive::before {
              width: 100%;
              transition: width 0.3s;
            }
            &:active,
            &:hover,
            &.menuLinkActive {
              color: $kiaPolarWhite;
            }
          }
        }
      }
    }
  }
}
.fleetImgBlock {
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(5, 20, 31, 0) 40%,
      $kiaMidnightBlack 100%
    );
  }
  .fleetImage {
    @media screen and (max-width: 991.98px) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: auto;
    }
  }
}
.fleetDetails {
  .headingElement {
    color: $kiaPolarWhite;
  }
  .btnCta {
    @media screen and (max-width: 576px) {
      padding: 20px 15px;
    }
  }
}
.fleetTabPanelVerticalContent {
  .fleetImgBlock {
    &::after {
      display: none;
    }
    .fleetImage {
      @media screen and (max-width: 991.98px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
      }
    }
  }
}
