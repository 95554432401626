@use '@/styles/utils/mixins.scss' as *;

.headingElement {
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    &.titleXl {
      font-size: 60px;
      line-height: 60px;
      @include tab() {
        font-size: 48px;
        line-height: 60px;
      }
      @include mob() {
        font-size: 36px;
        line-height: 43.2px;
      }
    }
    &.titleLg {
      font-size: 48px;
      line-height: 60px;
      @include tab() {
        font-size: 36px;
        line-height: 43.2px;
      }
      @include mob() {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    &.titleMd {
      font-size: 24px;
      line-height: 36px;
    }
    &.titleXs {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
  .titleText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
  }
}
.blockDetails {
  .headingElement {
    color: $kiaPolarWhite;
  }
  .titleText {
    p:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
