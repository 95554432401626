@use '@/styles/utils/mixins.scss' as *;

.promotionsCard {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  min-height: 565px;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 768px) and (max-width: 991.98px) and (orientation: portrait) {
    min-height: 500px;
  }
  @media screen and (max-width: 812px) and (orientation: landscape) {
    min-height: 535px;
  }
  @media screen and (max-width: 767px) {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 1) 100%
    );
    @media screen and (min-width: 992px) and (max-width: 1024px) {
      height: 30%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      height: 80%;
    }
  }
  .promotionsCardImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    object-fit: fill;
    z-index: -1;
  }
  .promotionsCardHeader {
    .promotionTag {
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14.4px;
        color: $kiaPolarWhite;
        margin-top: 2px;
      }
    }
    .darkTag {
      background: $kiaMidnightBlack;
    }
    .redTag {
      background: $kiaLiveRed;
    }
  }
  .promotionsCardBody {
    z-index: 2;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      min-height: 160px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );

      @media screen and (max-width: 991.98px) {
        min-height: 90px;
      }
    }
  }
  .promotionsCardFooter {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 1) 100%
    );
    background-color: rgba(0, 0, 0, 1);
    z-index: 2;
    position: relative;
    .carModelName {
      font-size: 36px;
      line-height: 43.2px;
      font-weight: 600;
      color: $kiaPolarWhite;
      display: inline-block;
      @media screen and (min-width: 767px) and (max-width: 991.98px) {
        font-size: 28px;
        line-height: 36.2px;
      }
      @media screen and (min-width: 992px) and (max-width: 1170px) {
        font-size: 30px;
        line-height: 38.2px;
      }
    }
    .carModelDetail {
      .carInfo {
        .description {
          color: $kiaPolarWhite;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          display: inline-block;
        }
      }
      .carPriceInfo {
        .carPriceDesc {
          color: $kiaPolarWhite;
          font-size: 12px;
          line-height: 14.4px;
        }
        .carPrice {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: $kiaPolarWhite;
          @media screen and (min-width: 767px) and (max-width: 991.98px) {
            font-size: 16px;
            line-height: 20px;
          }
          @media screen and (min-width: 992px) and (max-width: 1180px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
    .footerCTA {
      .btnCTA {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
